import "./WorkCardStyles.css";
import pro1 from "../assets/imagem1.png"

const WorkCard = (props) => {
  return <div className="work-container">
    <div className="project-container">
      <div className="project-card">
        <img className="img1" src={props.imgsrc} alt="image" />
        <h2 className="project-tittle">{props.title}</h2>
        <div className="pro-datails">
            <p>{props.text}</p>
        </div>
      </div>
    </div>
  </div>;
};

export default WorkCard;

import "./PricingCardStyles.css";
import { Link } from "react-router-dom";

const PricingCard = () => {
  return (
    <div className="pricing">
      <div className="card-container">
        <div className="card" data-aos="fade-up">
          <h3>- Sites -</h3>
          <span className="bar"></span>
          <p>- Informativos -</p>
          <p>- Empresariais -</p>
          <p className="teste">- Landing pages -</p>
          <Link to="/contact" className="btn">
            Saiba mais
          </Link>
        </div>

        <div className="card" data-aos="fade-up">
          <h3>- Sistemas Web -</h3>
          <span className="bar"></span>
          <p>- Controle de Estoque -</p>
          <p>- Gestão Empresarial -</p>
          <p className="teste">- Controle de Qualidade -</p>
          <Link to="/contact" className="btn">
            Saiba mais
          </Link>
        </div>

        <div className="card" data-aos="fade-up">
          <h3>- Tem alguma ideia? -</h3>
          <span className="bar"></span>
          <p>- Fale conosco -</p>
          <p>- Criação de sistemas -</p>
          <p className="teste">- Personalizados -</p>
          <Link to="/contact" className="btn">
            Saiba mais
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PricingCard;

import "./AboutContentStyles.css";
import { Link } from "react-router-dom";

import React from "react";

const AboutContent = () => {
  return <div className="about">
    <div className="textAbout">
      <h1>Quem sou eu?</h1>
      <p>Sou desenvolvedor autônomo, especializado em criar soluções completas, desde o desenvolvimento até o deploy em produção. Estou cursando o quarto semestre de Análise e Desenvolvimento de Sistemas na UNIARP, em Caçador-SC, sempre buscando evolução constante e me mantendo atualizado com as novas tecnologias.
        Atualmente, trabalho com JavaScript e React para o desenvolvimento front-end, Python e Quarkus no back-end, além de SQL para gerenciamento de banco de dados. Também utilizo Docker para facilitar o gerenciamento de ambientes e a implantação de sistemas de maneira eficiente e escalável.
        Trabalho de forma independente, assumindo todas as etapas de um projeto, desde a concepção até a entrega, garantindo a qualidade em cada fase do processo. Meu foco é oferecer soluções sob medida e construir uma interação próxima com o cliente para materializar suas ideias de forma sólida e inovadora.
      </p>
      <Link to="/contact" className="btn">Contatos</Link>
    </div>
    <div className="gifAbout">
      <img src="https://i.pinimg.com/originals/b3/ea/ad/b3eaad11c26ea7555cce955b69df4bb8.gif" alt="" />
    </div>

  </div>;
};

export default AboutContent;

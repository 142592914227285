import { FaHome, FaInstagram, FaLinkedin, FaMailBulk, FaPhone, FaWhatsapp, FaGithub } from "react-icons/fa";
import "./FooterStyles.css";

import React from "react";

const Footer = () => {
  return <div className="footer">
    <div className="footer-container">
      <div className="left">
        <div className="location">
          <FaHome size={20} style={{color: "#fff", marginRight: "2rem"}}/>
          <div>
            <p>Caçador - SC</p>
          </div>
        </div>
        <div className="phone">
          <h4>         
            <FaPhone size={20} style={{color: "#fff", marginRight: "2rem"}}/>
            &#40;47&#41; 99943-1401
          </h4>
        </div>
        <div className="email">
          <h4>         
            <FaMailBulk size={20} style={{color: "#fff", marginRight: "2rem"}}/>
            michaelreques@gmail.com
          </h4>
        </div>
      </div>
      <div className="right">
          <h4>Sobre minha carreira</h4>
          <p>Sou desenvolvedor autônomo, cursando o 4º 
            semestre de Análise e Desenvolvimento de Sistemas na UNIARP, Caçador-SC. 
            Busco abrir meu próprio negócio, focando em qualidade
            e na realização das ideias dos clientes.
          </p>
          <div className="social">       
            <a href="https://www.linkedin.com/in/michael-reques/" target="blank"><FaLinkedin size={20} style={{color: "#fff", marginRight: "1rem"}}/></a>
            <a href="https://www.instagram.com/michael_reques/" target="blank"><FaInstagram size={20} style={{color: "#fff", marginRight: "1rem"}}/></a>
            <a href="https://web.whatsapp.com/send?phone=5547999431401" target="blank"><FaWhatsapp size={20} style={{color: "#fff", marginRight: "1rem"}}/></a>
            <a href="https://github.com/Mikasfr" target="blank"><FaGithub size={20} style={{color: "#fff", marginRight: "1rem"}}/></a>
          </div>
      </div>
    </div>
  </div>;
};

export default Footer;

import pro1 from "../assets/imagem1.png"
import pro2 from "../assets/imagem2.jpg"
import pro3 from "../assets/imagem3.png"

const ProjectCardData = [
    {
        imgsrc: pro1,
        title:"Aplicação de controle de qualidade",
        text:"A aplicação web de controle de qualidade, desenvolvida com Quarkus no back-end, React no front-end e Docker para gerenciamento de containers, captura e armazena imagens de produtos junto ao número da comanda. Os pedidos ficam disponíveis para análise por até 30 dias. Totalmente personalizável, a solução pode ser adaptada às necessidades de cada estabelecimento, garantindo flexibilidade no controle de qualidade."
    },
    {
        imgsrc: pro2,
        title:"Contagem de objetos com IA",
        text:"Atualmente em desenvolvimento, que utiliza inteligência artificial para a contagem de pizzas em tempo real, através de uma câmera. Baseado na biblioteca OpenCV, o sistema será capaz de identificar e contabilizar cada pizza produzida, aumentando a eficiência operacional. Com o uso de algoritmos de visão computacional, oferece uma solução personalizável para diferentes estabelecimentos, garantindo precisão e adaptabilidade em processos industriais."
    },
    {
        imgsrc: pro3,
        title:"Sistema Web para Centro de Bem-Estar Animal",
        text:"Como parte do trabalho integrador da faculdade, estamos desenvolvendo uma aplicação web para gerenciamento de centros de bem-estar animal, utilizando Django como framework. O projeto está sendo construído desde o início, passando por todas as etapas fundamentais: levantamento de requisitos, criação de diagramas, modelagem de dados e prototipação. Agora, estamos na fase de desenvolvimento, transformando o planejamento e os protótipos em uma solução funcional."
    },
];

export default ProjectCardData;
import React from "react";
import Navbar from "../components/Navbar";
import HeroImg from "../components/HeroImg";
import Footer from "../components/Footer";
import PricingCard from "../components/PricingCard";

const Home = () => {
  return <>
    <title>Michael Reques</title>
    <link rel="stylesheet" href="https://i.pinimg.com/originals/e6/f0/7b/e6f07b25bda6bd3d24a6cf9cb2477202.gif" />
    <Navbar/>
    <HeroImg/>
    <PricingCard/>
    <Footer/>
  </>;
};

export default Home;

import "./FormStyles.css";
import { FaLinkedin, FaInstagram, FaWhatsapp, FaGithub } from "react-icons/fa";
import { useState } from "react";
import React from "react";
import emailjs from "@emailjs/browser";

const Form = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [assunto, setAssunto] = useState('');
  const [message, setMessage] = useState('');
  const [buttonText, setButtonText] = useState('Enviar'); // Texto do botão
  const [buttonColor, setButtonColor] = useState('#790101'); // Cor do botão
  const [isDisabled, setIsDisabled] = useState(false); // Estado para desabilitar o botão

  function sendEmail(e) {
    e.preventDefault();
    
    const templateParams = {
      from_name: name,
      message: message,
      assunto: assunto,
      email: email
    };
    
    setIsDisabled(true); // Desabilitar o botão após o envio
    setButtonText('Enviando...'); // Alterar o texto para 'Enviando...'

    emailjs.send("service_ubdnyha", "template_qesy7ix", templateParams, "j2BL1aeGmLStgCh1u")
      .then((response) => {
        console.log("Email enviado", response.status, response.text);
        setButtonText('Enviado'); // Texto de sucesso
        setButtonColor('#28a745'); // Cor verde para sucesso
        setTimeout(() => {
          setButtonText('Enviar'); // Restaurar estado inicial
          setButtonColor('#790101'); // Restaurar cor inicial
          setIsDisabled(false); // Reativar o botão
        }, 2000);
        setName(""); // Limpar campos
        setAssunto("");
        setEmail("");
        setMessage("");
      }, (err) => {
        console.log("ERRO", err);
        setButtonText('Erro ao Enviar'); // Texto de erro
        setButtonColor('#dc3545'); // Cor vermelha para erro
        setTimeout(() => {
          setButtonText('Enviar'); // Restaurar estado inicial
          setButtonColor('#790101'); // Restaurar cor inicial
          setIsDisabled(false); // Reativar o botão
        }, 2000);
      });
  }

  return (
    <div className="contatos">
      <div className="esquerda">
        <div className="gif">
          <img src="https://i.pinimg.com/originals/c9/7e/ff/c97eff36e2151f5c27e64562060ac6e3.gif" alt="GIF animado" />
        </div>
        <div className="links">
          <a href="https://www.linkedin.com/in/michael-reques/" target="blank" aria-label="LinkedIn">
            <FaLinkedin size={40} style={{color: "#fff", marginRight: "1rem"}}/>
          </a>
          <a href="https://www.instagram.com/michael_reques/" target="blank" aria-label="Instagram">
            <FaInstagram size={40} style={{color: "#fff", marginRight: "1rem"}}/>
          </a>
          <a href="https://web.whatsapp.com/send?phone=5547999431401" target="blank" aria-label="WhatsApp">
            <FaWhatsapp size={40} style={{color: "#fff", marginRight: "1rem"}}/>
          </a>
          <a href="https://github.com/Mikasfr" target="blank" aria-label="Github">
            <FaGithub size={40} style={{color: "#fff", marginRight: "1rem"}}/>
          </a>
        </div>
      </div>

      <form className="form" onSubmit={sendEmail}>
        <label>Seu Nome</label>
        <input type="text" required onChange={(e) => setName(e.target.value)} value={name} />

        <label>Email</label>
        <input type="email" required onChange={(e) => setEmail(e.target.value)} value={email} />

        <label>Assunto</label>
        <input type="text" required onChange={(e) => setAssunto(e.target.value)} value={assunto} />

        <label>Mensagem</label>
        <textarea rows="6" placeholder="Escreva sua mensagem aqui" required onChange={(e) => setMessage(e.target.value)} value={message} />

        {/* Botão que muda dinamicamente */}
        <button 
          className="btn" 
          type="submit" 
          style={{ backgroundColor: buttonColor }} 
          disabled={isDisabled} // Desabilitar o botão quando o email estiver sendo enviado
        >
          {buttonText}
        </button>
      </form>
    </div>
  );
};

export default Form;

import "./WorkCardStyles.css";
import WorkCard from "./WorkCard";
import WorkCradData from "./WorkCradData";


const Work = () => {
  return <div className="work-container">
    <div className="project-container">
      {WorkCradData.map((val, ind) => {
        return<>
          <WorkCard
          key={ind}
          imgsrc={val.imgsrc}
          title={val.title}
          text={val.text}
          />
        </>
      })}
    </div>
  </div>;
};

export default Work;

import "./HeroImg3Styles.css";
import React, { Component } from "react";

const HeroImg3 = () => {
    return <div className="hero-img">
      <div className="heading">
        <div className="video">
            <img src="https://i.pinimg.com/originals/8d/44/28/8d44285958161178e861f78d655ce22d.gif" alt="" />
        </div>
        <div className="text">
          <h1>Sobre</h1>    
          <p>Um pouco sobre mim</p>
        </div>
      </div>
      <span className="barAbout"></span>
    </div>
}

export default HeroImg3;

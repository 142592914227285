import "./HeroImgStyles.css";

import React from "react";
import IntroImg from "../assets/intro-bg.png" 
import { Link } from "react-router-dom";

const HeroImg = () => {
  return <div className="hero">
    <div className="mask">
      <img className="into-img" src={IntroImg} alt="IntroImg" />
    </div>
    <div className="content">
      <p>Freelancer</p>
      <h1>Web Developer</h1>
      <div>
        <Link to="/project" className="btn">
          Projetos
        </Link>
        <Link to="/contact" className="btn-light">
          Contatos
        </Link>
      </div>
    </div>
    <span className="barAbout"></span>
  </div>;
};

export default HeroImg;

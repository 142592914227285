import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import HeroImg4 from "../components/HeroImg4";
import Form from "../components/Form";

const Contact = () => {
  return <>
    <Navbar/>
    <HeroImg4/>
    <Form/>
    <Footer/>
  </>;
};

export default Contact;

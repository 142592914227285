import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import HeroImg2 from "../components/HeroImg2";
import Work from "../components/Work";

const Project = () => {
  return <>
    <link rel="shortcut icon" type="image/jpg" href="https://media3.giphy.com/media/v1.Y2lkPTc5MGI3NjExNzFjaTdrOHpidGkxMzl0YzBnemMzbXJsNWtocmYweG41N3NlaXpudyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9cw/TfSLlc574q6HgAy9Lb/giphy.webp" />
    <Navbar/>
    <HeroImg2/>
    <Work/>
    <Footer/>
  </>;
};

export default Project;

import "./index.css";
import Home from "./routes/Home";
import About from "./routes/About";
import Project from "./routes/Project";
import Contact from "./routes/Contact";
import "aos/dist/aos"
import ScrollToTop from "./components/ScrollToTop";

import { Route, Routes } from "react-router-dom";

// const App = () => {
//   useEffect(() => {
//     AOS.init({
//       duration: 1000,  // Duração da animação em milissegundos
//       once: true,      // Se true, a animação será executada apenas uma vez
//     });
//   }, []);

function App() {
  return (
    <>
    <ScrollToTop/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/project" element={<Project />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </>
  );
}


export default App;

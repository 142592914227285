import "./HeroImg4Styles.css";
import React, { Component } from "react";

const HeroImg3 = () => {
    return <div className="hero-img">
      <div className="heading">
        <div className="video">
            <img src="https://i.pinimg.com/originals/27/2e/99/272e99ff8422acec9e661db5cb717a8b.gif" alt="" />
        </div>
        <div className="text">
          <h1>Contatos</h1>
          <p>Meios por onde me encontrar</p>   
        </div>
      </div>
    </div>
}

export default HeroImg3;
